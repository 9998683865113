import Particles from "react-tsparticles";

function Background() {
  const particlesInit = (main) => {};
  const particlesLoaded = (container) => {};

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fpsLimit: 120,
        interactivity: {
          modes: {
            bubble: {
              distance: 10,
              duration: 1,
              opacity: 0.8,
              size: 40,
            },
          },
        },
        particles: {
          color: {
            value: "#B3B7BB",
          },
          links: {
            color: "#B3B7BB",
            distance: 150,
            enable: true,
            opacity: 0.25,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.25,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
  );
}

export default Background;
