import React, { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { db } from "services/firebase";
// import ModelService from "services/ModelService";
import { Icon } from "utils/fontawesome";
import { Create, Update, Delete } from "./";

function ModelsTable() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const unsubscribe = db.collection("models").onSnapshot((snapshot) => {
      setCategories(
        snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
      );
    });

    return unsubscribe;
  }, [setCategories]);

  const closeModal = () => {
    setSelectedCategory({});
    setShowDetails(false);
  };

  const editModel = (model) => {
    setShowDetails(true);
    setSelectedCategory(model);
  };

  return (
    <>
      <Update
        model={selectedCategory}
        show={showDetails}
        handleClose={closeModal}
      />

      <span>
        <h1 className="h3">Categories</h1>
        <Create />
        <Delete />
      </span>

      <Table height={800} data={categories} onRowClick={editModel}>
        <Column width={50} fixed>
          <HeaderCell>Icon</HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Icon icon={rowData.icon} color={rowData.color ?? "#000"} />
              );
            }}
          </Cell>
        </Column>

        <Column width={150} fixed>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>
      </Table>
    </>
  );
}

export default ModelsTable;
