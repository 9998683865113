import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Alert } from "react-bootstrap";

import useSessionId from "games/useSessionId";

// import { CircularProgress, Stack, Typography } from "@mui/material";

/* 


  Frontend submission steps:

  1. Make a fetch request
  2. Parse the JSON response
  3. Display the results to the player
  
  */

const SubmissionReceived = () => {
  const sessionId = useSessionId();
  // results loaded based on the submission id
  // const [results, setResults] = React.useState(null);
  // const { id: gameId } = useParams();

  /* call this url to get begin processing the R script */
  // const base = 'http://127.0.0.1:5001';
  // const url = `${base}/esgmachine-36d4e/us-central1/r-executeExAsync?id=${id}`;

  React.useEffect(() => {
    if (sessionId) {
      const END_LINK = `https://survey.maximiles.com/complete?p=125959_23a55ef0&m=${sessionId}`;
      const redirectTimer = setTimeout(() => {
        window.location.replace(END_LINK);
      }, 3000); // Redirect after 3 seconds

      return () => clearTimeout(redirectTimer);
    }
  }, [sessionId]);

  // Draw the view
  return (
    <>
      <Container className="py-4">
        <Alert variant="success">
          <span className="alert-heading h4">
            <FontAwesomeIcon icon="fa-check-circle" />
            {" Submission Received!"}
          </span>
          <p>
            Thank you! Your submission has been received, and your contribution is greatly
            appreciated!
          </p>
          {sessionId && <p>You will be redirected shortly...</p>}
        </Alert>
      </Container>
    </>
  );
};

export default SubmissionReceived;
