import { functions } from "./firebase.js";

// users
export const listUsers = functions.httpsCallable("users-listUsers");
export const resetPassword = functions.httpsCallable("users-resetPassword");
// roles based authentication
export const grantAdmin = functions.httpsCallable("role-grantAdmin");
export const revokeAdmin = functions.httpsCallable("role-revokeAdmin");

// data
export const exportCSV = functions.httpsCallable("export-csv");
export const r = functions.httpsCallable("r");
