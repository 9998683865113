import { interpolateColors } from "utils/color-generator";

/**
 * Represents the state of the Game.
 * @constructor
 * @param {string} id - The id of the game
 * @param {string} name - The name of the game
 * @param {[round]} rounds - An array of round configurations for which to play
 */

// returns the first x number of items in the data model array
export function configureValues(total, models, startAtZero = false) {
  var results = models ?? [];

  if (startAtZero) {
    // Initialize all values to 0
    results.forEach((result) => {
      result.value = 0;
    });
  } else {
    // Randomly distribute values to total points
    var random_points = shuffle(generate(total, models.length));
    results.forEach((result, index) => {
      result.value = random_points[index];
    });
  }

  return results;
}

// The following random number generator comes from the following src:
// https://stackoverflow.com/a/19278621/14838885
// It provides a method of generating `n` amount of numbers
// all when added up equal the sum provided
function generate(max, total) {
  var r = [];
  var sum = 0;
  for (var i = 0; i < total - 1; i++) {
    r[i] = roundNearest10(randomBetween(1, max - (total - i - 1) - sum));
    sum += r[i];
  }
  r[total - 1] = max - sum;
  return r;
}

// generate a random number between the min and max values
function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// round the number to the nearest 10th
function roundNearest10(num) {
  return Math.ceil(num / 10) * 10;
}

// shuffle the contents of an array
export function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

// Applies the mapping model to the current session
export const arrangeModels = ({ models, model_map }) => {
  // var newModels = models.sort((a, b) => {
  //   return model_map[a.name] - model_map[b.name];
  // });

  var colors = interpolateColors(models.length);
  return models.map((model) => {
    model.color = colors[models.indexOf(model)];
    return model;
  });
};

export function sumValues(models) {
  return models.map((i) => i.value).reduce((a, b) => a + b, 0);
}

// calculates the summed and multiplied values of the models
export function multipliedSum(models) {
  const multiplied = models.map((i) => i.value * i.multiplier) ?? [];
  const sum = multiplied.reduce((a, b) => a + b, 0);
  return sum;
}

export const generateModelMap = (game) => {
  var results = {};

  // randomize the list order for this session
  let models = shuffle(game.rounds.flatMap((r) => r.models) ?? []);

  models.forEach((model, index) => {
    if (!results[model.name]) {
      results[model.name] = Number(index);
    }
  });

  return models;
};
