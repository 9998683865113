import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import UserService from "services/UserService";

function EditUser(props) {
  const { uid, email, customClaims } = props.user;

  function isUserAdmin() {
    return (customClaims ?? []).includes("admin");
  }

  function setUserRole(newIsAdmin) {
    UserService.setUserIsAdmin(uid, newIsAdmin)
      .then((res) => {
        alert("This users privileges have been updated successfully.");
        props.handleClose();
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  function resetUsersPassword() {
    UserService.resetPasswordForUser(email)
      .then((res) => {
        alert("A password reset link has been sent!");
        props.handleClose();
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={email}
                type="email"
                placeholder="email address"
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="matchValue">
              <Form.Label>Admin</Form.Label>
              <Form.Check
                type="checkbox"
                checked={isUserAdmin()}
                onChange={(e) => {
                  setUserRole(Boolean(e.target.checked));
                }}
              />
            </Form.Group>
            <Button variant="outline-danger" onClick={resetUsersPassword}>
              Email password reset
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUser;
