import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Alert } from "react-bootstrap";

const Unauthorized = () => (
  <>
    <Container className="py-4">
      <Alert variant="danger">
        <span className="alert-heading h4">
          <FontAwesomeIcon icon="fas fa-exclamation-triangle" />
          {"Unauthorized"}
        </span>
        <p>
          Uh oh! It looks like you are trying to access a page with out the
          correct privileges... Make sure to login if you have access.
        </p>
      </Alert>
    </Container>
  </>
);

export default Unauthorized;
