import React from "react";

import { Stack, Paper, Box } from "@mui/material";

import MatrixRow from "./Row";

const MatrixControls = ({ inputControls, selection, onChange, sx }) => {
  const selectionDescription = (key) => {
    let options = Object.values(inputControls[key]?.options ?? {});
    let activeIndex = selection[key];
    let result = options[activeIndex];
    return result?.description ?? "";
  };

  return (
    <Stack direction="column" spacing={1} margin={1} sx={{ ...sx }}>
      {Object.keys(inputControls).map((key, index) => {
        var item = inputControls[key];
        item.index = key;
        item.description = selectionDescription(key);
        return (
          <Box sx={{ padding: 2 }} key={key} component={Paper}>
            <Stack spacing={2}>
              <MatrixRow
                key={item.title}
                value={selection[item.index] ?? 0}
                onChange={(e) => {
                  onChange({ ...item, value: e.value });
                }}
                item={item}
                inputControls={{
                  index: index,
                  value: item.value,
                }}
              />
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};

export default MatrixControls;
