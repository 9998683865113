import React, { useEffect, useState } from "react";

import Select from "react-select";

import { db } from "services/firebase";
import CategoryRow from "components/CategoryRow";
import { Icon } from "utils/fontawesome";
import EditCategoryRow from "./Row";

function CategoriesPicker({ value, readOnly, onChange, mode }) {
  const [allModels, setAllModels] = useState([]);

  // fetches the model templates
  useEffect(() => {
    const unsubscribe = db.collection("models").onSnapshot((snapshot) => {
      let results = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      setAllModels(results);
    });

    return unsubscribe;
  }, [setAllModels]);

  // add the selected models to the list for configuring
  const selectionChanged = (values, event) => {
    var newSelection = values.map((i) => i.value);

    var template = allModels
      .filter((m) => {
        return newSelection.includes(m.name);
      })
      .map((m) => {
        return { ...m, ...value.find((e) => e.name === m.name) };
      })
      .map((m) => {
        return { multiplier: m.multiplier ?? { low: 1, high: 1 }, ...m };
      });

    onChange({ models: template });
  };

  const modelChanged = ({ index, multiplier }) => {
    // make a copy; update it; apply it back to the array
    var selection = [...value];
    var model = { ...selection[index], multiplier };
    selection[index] = model;
    onChange({ models: selection });
  };

  const labelMapping = (obj) =>
    obj
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item, index) => {
        return {
          index: index,
          label: item.name,
          value: item.name,
          ...item,
        };
      });

  return (
    <>
      <Select
        value={labelMapping(value)}
        options={labelMapping(allModels)}
        isMulti
        closeMenuOnSelect={false}
        onChange={selectionChanged}
        isDisabled={readOnly}
        getOptionLabel={(e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon icon={e.icon ?? ""} color={e?.color ?? " #000"} size="lg" padding={1} />
            {/* {props.data.icon} */}
            <p>{e.label}</p> <span style={{ marginLeft: 5 }}>{e.text}</span>
          </div>
        )}
      />
      <br />
      <span>Configure Values:</span>
      {value.map((item, index) => {
        return mode === "points" || mode === "donut" ? (
          <EditCategoryRow
            mode={mode}
            key={`row-${item.name}-${index}`}
            item={item}
            index={index}
            onChange={modelChanged}
            readOnly={readOnly}
          />
        ) : (
          <CategoryRow key={`row-${item.name}-${index}`} item={item} index={index} />
        );
      })}
    </>
  );
}

export default CategoriesPicker;
