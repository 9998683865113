import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { color_scheme } from "utils/color-generator";

// Input form to select a color scheme
const ColorSchemePicker = ({ scheme, onChange }) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Color Scheme</InputLabel>
        <Select
          value={scheme ?? ""}
          label="Color Scheme"
          onChange={(e) => {
            onChange({ scheme: e.target.value });
          }}
        >
          {color_scheme.map((c) => (
            <MenuItem key={c.value} value={c.value}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ColorSchemePicker;
