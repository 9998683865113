import React from "react";

import { Badge } from "react-bootstrap";

import { Icon } from "utils/fontawesome";
import ESGSlider from "./ESGSlider";

const ESGRow = ({ item, config, onChange, max }) => {
  const { name, icon, value, multiplier, color } = item;
  const { showValues, showSlider, index, total } = config || {};

  const valuesView = () => {
    return !!showValues ? (
      <>
        <span className="px-2 h5">
          {value}
          <sup className="text-muted">$</sup>
        </span>
        <span className="h6">
          <Badge bg="secondary">
            x{Number.isInteger(multiplier) ? multiplier : multiplier.toFixed(2)}
          </Badge>
        </span>
      </>
    ) : (
      <></>
    );
  };

  const sliderView = () => {
    return !!showSlider ? (
      <div className="py-1">
        <ESGSlider {...{ item, index, total, onChange, value, max }} />
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <span className="px-2 h5">
          <Icon icon={icon} color={color} />
          <strong className="px-2">{name}</strong>
        </span>
        <div className="py-1">{valuesView()}</div>
      </div>
      {sliderView()}
    </>
  );
};

export default ESGRow;
