import react, { useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

import logo from "../imgs/logo.png";
import { auth } from "../services/firebase";

function Header() {
  const [user, setUser] = useState(null);
  react.useEffect(() => {
    const unlisten = auth.onAuthStateChanged((authUser) => {
      authUser ? setUser(authUser) : setUser(null);
    });
    return () => {
      unlisten();
    };
  }, []);

  const AuthenticationLinks = () => {
    return (
      <>
        <Nav.Link as={NavLink} to="/signin" className="mx-2">
          Sign In
        </Nav.Link>
        <Nav.Link as={NavLink} to="/signup" className="mx-2">
          Sign Up
        </Nav.Link>
      </>
    );
  };

  const AuthenticatedLinks = () => {
    return (
      <>
        {user ? AdminLinks() : null}
        <Nav.Link as={NavLink} to="/signout" className="mx-2">
          Sign Out
        </Nav.Link>
      </>
    );
  };

  const AdminLinks = () => {
    return (
      <Nav.Link as={NavLink} to="/admin">
        Admin
      </Nav.Link>
    );
  };

  react.useEffect(() => {
    if (window.google?.translate?.TranslateElement) {
      return new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages: "en,es,fr,ru,zh,vi,ar",
          layout: window.google?.translate?.TranslateElement?.InlineLayout?.SIMPLE,
          autoDisplay: false,
          multilanguagePage: true,
        },
        "google_translate_element"
      );
    }
  }, [window.google?.translate?.TranslateElement]);

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Link to="/">
          <Navbar.Brand>
            <img
              src={logo}
              width="28"
              height="28"
              className="d-inline-block align-top mx-2"
              alt="ESG Machine logo"
            />
            <b>ESGMACHINE</b>
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="navbarScroll">
          {!user ? AuthenticationLinks() : AuthenticatedLinks()}
          <div className="flex-grow-1"></div>

          <Nav.Link className="justify-content-end">
            <div className="justify-content-end" id="google_translate_element"></div>
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
