import { useState, useEffect } from "react";

const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org");
        if (!response.ok) {
          throw new Error("Failed to fetch IP address");
        }
        const ip = await response.text();
        setIpAddress(ip);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchIpAddress();
  }, []); // Fetch IP address only once when the component mounts

  return ipAddress;
};

export default useIpAddress;
