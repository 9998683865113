import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import GameService from "services/GameService";

export default function NukeGames(params) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const nuke = () => {
    GameService.nukeGames(() =>
      window.confirm("You are about to delete all games. Are you sure?")
    ).then((didNuke) => {
      handleClose();
    });
  };

  return (
    <>
      <Button className="m-2" variant="danger" onClick={handleShow}>
        Delete All Games
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete all games?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete all games? <br />
          Current and past games will be erased and unrecoverable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={nuke}>
            Delete All Games
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
