import React, { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import UserService from "services/UserService";
import EditUserForm from "./EditUserForm";

function UsersTable(props) {
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const closeModal = () => {
    setSelectedUser({});
    setShowDetails(false);
  };

  useEffect(() => {
    (async () => {
      let results = await UserService.listAllUsers();
      if (results) setUsers(results);
    })();
  }, []);

  return (
    <>
      <span>
        <h1 className="h3">Users</h1>
      </span>

      <EditUserForm
        user={selectedUser}
        show={showDetails}
        handleClose={closeModal}
      />

      <Table
        height={800}
        data={users}
        onRowClick={(data) => {
          setSelectedUser(data);
          setShowDetails(true);
        }}
      >
        <Column width={300} fixed>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>

        <Column width={300} fixed>
          <HeaderCell>Privileges</HeaderCell>
          <Cell>
            {(user) => {
              return user.customClaims ?? [].join(", ");
            }}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

export default UsersTable;
