import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PublishButton from "./PublishButton";
import GameService from "services/GameService";
import SubmissionService from "services/SubmissionService";
import GameForm from "./Form";
import DeleteGameSubmissions from "./DeleteGameSubmissions";

function EditGame({ game, show, handleClose }) {
  const [gameConfig, setGameConfig] = useState(game ?? {});

  const publishGame = (status) => {
    GameService.updateGame({ ...gameConfig, status }).then(() => {
      handleClose();
    });
  };

  const archiveGame = () => {
    GameService.deleteGame(gameConfig.id).then(handleClose);
  };

  const handleSubmissions = () => {
    console.log("Subs for game...", gameConfig.id);
    SubmissionService.getSubmissions(gameConfig.id)
      .then((jsonData) => {
        // Create a JSON blob
        const jsonBlob = new Blob([JSON.stringify(jsonData)], {
          type: "application/json",
        });

        // Create a URL for the blob object
        const url = URL.createObjectURL(jsonBlob);

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = url;
        link.download = `${gameConfig?.name.replace(
          " ",
          "-"
        )}-submissions.json`;

        // Programmatically click the anchor to trigger the download
        link.click();

        // Clean up the temporary anchor
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Game Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GameForm game={game} onChange={setGameConfig} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="m-2" variant="danger" onClick={archiveGame}>
            Delete Game
          </Button>
          <Button className="ms-auto" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="m-2" variant="primary" onClick={handleSubmissions}>
            Download Submissions
          </Button>
          <DeleteGameSubmissions gameId={game.id} />

          <PublishButton status={game.status} onClick={publishGame} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditGame;
