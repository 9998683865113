import React, { useMemo } from "react";

import {
  Stack,
  Button,
  Typography,
  TextField,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import _ from "lodash";
import ColorSchemePicker from "components/ColorSchemePicker";
import EditRow from "./Row/Edit";
import MoreMenu from "./Row/MoreMenuButton";
import compute from "./compute";

const MatrixForm = ({
  config: { baseline = {}, inputControls = {}, scheme, shuffle },
  onChange,
}) => {
  const [expandedAccordion, setExpandedAccordion] = React.useState(null);

  const { suggestedRange } = useMemo(() => {
    return compute({
      baseline,
      inputControls,
      scheme,
      shuffle,
    });
  }, [baseline, inputControls, scheme, shuffle]);

  const handleChange = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : null);
  };

  // Create a new matrix column with an optional template
  const addColumn = (template) => {
    var result = { ...(baseline ?? {}) };
    let index = Object.keys(result).length;
    result[index] = template ?? { title: "", description: "" };
    onChange({ baseline: result });
  };

  // Remove a column at a given index
  const dropCol = (index) => {
    var result = { ...baseline };
    delete result[index];
    onChange({ baseline: result });
  };

  // remaps value from the control and passes it to parent
  const baselineChanged = (index, data) => {
    var result = { ...baseline };
    var ele = result[index];
    ele = { ...ele, ...data };
    result[index] = ele;
    onChange({ baseline: result });
  };

  // Create a new matrix row with an optional template
  const addRow = (template) => {
    var result = { ...inputControls };
    let index = Object.keys(result).length;
    result[index] = template ?? { title: "" };
    onChange({ inputControls: result });
    // update the current selected accordion
    setExpandedAccordion(String(index));
  };

  // Remove a column at a given index
  const dropRow = (index) => {
    var result = { ...inputControls };
    delete result[index];
    onChange({ inputControls: result });
  };

  // remaps value from the matrix rows and passes to parent
  const inputControlsChanged = (index, data) => {
    onChange({
      inputControls: {
        ...inputControls,
        [index]: { ...inputControls[index], ...data },
      },
    });
  };

  const handleOption = (option, value, index) => {
    const op = option.toLowerCase();
    switch (op) {
      case "delete":
        return dropCol(index);

      case "duplicate":
        return addColumn(value);

      default:
        console.warn("unknown option: ", op);
        break;
    }
  };

  return (
    <Stack spacing={2}>
      <ColorSchemePicker scheme={scheme} onChange={onChange} />

      {/* Columns Baseline Values */}
      <Typography variant="h5" fontWeight="semibold">
        Baseline Values:
      </Typography>
      <Typography variant="p">
        Configure baseline values representing the input columns within the matrix.
      </Typography>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} sx={{ overflow: "auto" }}>
          {Object.keys(baseline ?? {}).map((key, index) => {
            let value = baseline[key];
            if (key === "title" || key === "description") return <></>;
            return (
              <Box sx={{ padding: 1, minWidth: "150px" }} component={Paper} key={index}>
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <TextField
                      value={value.title}
                      label={"Title"}
                      size="small"
                      key="title"
                      onChange={(e) => {
                        baselineChanged(index, { title: e.target.value });
                      }}
                    />
                    <MoreMenu handleOption={(option) => handleOption(option, value, index)} />
                  </Stack>

                  <TextField
                    type="number"
                    value={Number(value.value ?? 0)}
                    label={"Baseline"}
                    size="small"
                    onChange={(e) => {
                      baselineChanged(index, {
                        value: Number(e.target.value ?? 0),
                      });
                    }}
                  />
                  <Stack direction="row" spacing={1}>
                    <TextField
                      label="Min (optional)"
                      size="small"
                      placeholder={String(suggestedRange(index)?.min)}
                      type="number"
                      value={baseline[index]?.chartMinX}
                      onChange={(e) =>
                        baselineChanged(index, {
                          chartMinX: _.isEmpty(e.target.value) ? null : parseFloat(e.target.value),
                        })
                      }
                    />
                    <TextField
                      label="Max (optional)"
                      size="small"
                      placeholder={String(suggestedRange(index)?.max)}
                      type="number"
                      value={baseline[index]?.chartMaxX}
                      onChange={(e) =>
                        baselineChanged(index, {
                          chartMaxX: _.isEmpty(e.target.value) ? null : parseFloat(e.target.value),
                        })
                      }
                    />
                  </Stack>
                </Stack>
              </Box>
            );
          })}
          <Button variant="outlined" onClick={() => addColumn()}>
            +
          </Button>
        </Stack>

        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5" fontWeight="semibold">
              Categories:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shuffle}
                  onChange={(e) => {
                    onChange({ shuffle: e.target.checked });
                  }}
                />
              }
              label="Shuffle"
            />
          </Stack>

          <Typography variant="p">
            Configure categories representing the number of questions for the player to select
          </Typography>

          <InputControlsList
            {...{
              expandedAccordion,
              handleChange,
              inputControls,
              baseline,
              inputControlsChanged,
              addRow,
              dropRow,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const InputControlsList = ({
  expandedAccordion,
  handleChange,
  inputControls,
  baseline,
  inputControlsChanged,
  addRow,
  dropRow,
}) => {
  const accordionItems = useMemo(() => {
    return Object.entries(inputControls ?? {}).map(([index, value]) => (
      <Accordion key={index} expanded={expandedAccordion === index} onChange={handleChange(index)}>
        <AccordionSummary>
          <Typography variant="h6">{value?.title}</Typography>
          {/* Optionally include a title or summary for the accordion */}
        </AccordionSummary>
        <AccordionDetails>
          <InputControlsListItem
            item={inputControls[index]}
            baseline={baseline}
            dropRow={dropRow}
            addRow={addRow}
            index={index}
            inputControlsChanged={inputControlsChanged}
          />
        </AccordionDetails>
      </Accordion>
    ));
  }, [
    expandedAccordion,
    inputControls,
    handleChange,
    baseline,
    dropRow,
    addRow,
    inputControlsChanged,
  ]);

  return (
    <Stack direction="column" spacing={1}>
      {accordionItems}

      <Button variant="outlined" onClick={() => addRow()} fullWidth>
        +
      </Button>
    </Stack>
  );
};

const InputControlsListItem = ({
  index,
  item,
  baseline,
  addRow,
  dropRow,
  inputControlsChanged,
}) => (
  <Box sx={{ padding: 1, minWidth: "150px" }} component={Paper} key={index}>
    <Stack spacing={2}>
      <EditRow
        index={index}
        baseline={baseline}
        onChange={(data) => {
          inputControlsChanged(index, data);
        }}
        item={item}
        addRow={addRow}
        dropRow={dropRow}
      />
    </Stack>
  </Box>
);

export default MatrixForm;
