import React from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const AddQuestionForm = ({ questions, setQuestions }) => {
  const handleQuestionChange = (index, key, value) => {
    const newQuestions = [...questions];
    newQuestions[index][key] = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...(questions ?? []),
      { question: "", inputType: "", options: [] },
    ]);
  };

  const deleteQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push("");
    setQuestions(newQuestions);
  };

  const deleteOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  return (
    <Stack padding={1}>
      {(questions ?? []).map((question, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginY: "1rem",
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            label="Question"
            value={question.question}
            onChange={(e) =>
              handleQuestionChange(index, "question", e.target.value)
            }
          />
          <TextField
            size="small"
            variant="outlined"
            label="Description"
            value={question.description}
            onChange={(e) =>
              handleQuestionChange(index, "description", e.target.value)
            }
          />

          <FormControl variant="outlined" fullWidth>
            <InputLabel>Input Type</InputLabel>
            <Select
              size="small"
              value={question.inputType}
              label="Input Type"
              onChange={(e) =>
                handleQuestionChange(index, "inputType", e.target.value)
              }
            >
              <MenuItem value="">Select Input Type</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="select">Select</MenuItem>
              {/* <MenuItem value="checkbox">Checkbox</MenuItem> */}
            </Select>
          </FormControl>
          {question.inputType !== "" && question.inputType !== "text" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "1rem",
              }}
            >
              {question.options.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    label="Option"
                    value={option}
                    onChange={(e) =>
                      handleOptionChange(index, optionIndex, e.target.value)
                    }
                  />
                  <IconButton onClick={() => deleteOption(index, optionIndex)}>
                    <Delete />
                  </IconButton>
                </Box>
              ))}
              <Button variant="outlined" onClick={() => addOption(index)}>
                Add Option
              </Button>
            </Box>
          )}
          <Button
            variant="outlined"
            color="error"
            onClick={() => deleteQuestion(index)}
          >
            Delete Question
          </Button>
        </Box>
      ))}

      <Button
        fullWidth
        variant="contained"
        className="pt-1"
        onClick={() => addQuestion()}
      >
        Add Question
      </Button>
    </Stack>
  );
};
export default AddQuestionForm;
