import React from "react";
import { Icon } from "utils/fontawesome";

const CategoryRow = ({ index, item: { name, icon, color, multiplier } }) => {
  return (
    <li key={`${index}-${name}`} className="list-group-item">
      <div className="d-flex justify-content-between">
        <span>
          {Icon(icon, color)}

          <Icon icon={icon} color={color} />
          <strong className="px-2">{name}</strong>
        </span>
      </div>
    </li>
  );
};

export default CategoryRow;
