import React from "react";
import { Form } from "react-bootstrap";
import { IconPicker } from "utils/fontawesome";

function CategoryForm(props) {
  const { name, icon, onChange } = props;

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => props.onChange({ name: e.target.value })}
            type="text"
            placeholder="Name of the category"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="icon">
          <Form.Label>Icon</Form.Label>
          <Form.Control
            type="text"
            placeholder="Font Awesome Icon Name"
            value={icon}
            onChange={(e) => {
              props.onChange({ icon: e.target.value });
              //   setIcon(e.target.value);
            }}
          />
          <Form.Text>
            <small id="iconHelp" className="form-text text-muted">
              provided by{" "}
              <a href="http://fontawesome.com/search?o=r&m=free&s=solid&f=classic">
                FontAwesome
              </a>
            </small>
          </Form.Text>
          <IconPicker
            value={icon}
            onChange={(v) => {
              onChange({ icon: v });
            }}
          />
        </Form.Group>
      </Form>
    </>
  );
}

export default CategoryForm;
