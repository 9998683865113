import _ from "lodash";
import * as React from "react";

// MUI Imports
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";

// Font Awesome Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";

// prepares all the icons to be added to the library
const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);
library.add(...iconList);

// Primary Icon Container
// Convenience method to draw an icon
export const Icon = ({ icon, color, size }) => {
  if (!_.isEmpty(icon))
    return (
      <FontAwesomeIcon
        icon={icon}
        color={color ?? "#000"}
        size={size ?? "sm"}
      />
    );

  return <></>;
};

// Primary Picker Component
/// Displays a dialog containing a list
// from which a user may select an icon
export function IconPicker({ value, onChange }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    onChange(value);
  };

  return (
    <div sx="; ">
      <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
        <Icon size={"4x"} icon={value} />
      </Box>
      <Button variant="outlined" onClick={handleClickOpen} fullWidth>
        Select Icon
      </Button>
      <Picker selectedValue={value ?? ""} open={open} onClose={handleClose} />
    </div>
  );
}

// Icon list items available in the picker
const pickerList = [
  "house",
  "magnifying-glass",
  "user",
  "check",
  "image",
  "phone",
  "envelope",
  "star",
  "location-dot",
  "music",
  "wand-magic-sparkles",
  "heart",
  "bomb",
  "camera-retro",
  "cloud",
  "comment",
  "truck-fast",
  "face-smile",
  "calendar-days",
  "paperclip",
  "shield",
  "cart-shopping",
  "clipboard",
  "bolt",
  "car",
  "ghost",
  "umbrella",
  "gift",
  "film",
  "lock",
  "bookmark",
  "book",
  "tag",
  "headphones",
  "droplet",
  "fire",
  "eye",
  "hand",
  "plane",
  "magnet",
  "money-bill",
  "key",
  "code",
  "gears",
  "paper-plane",
  "flask",
  "compass",
  "palette",
  "gamepad",
  "bath",
  "earth-americas",
  "sun",
  "fish",
  "feather",
  "bug",
  "shop",
  "shirt",
  "anchor",
  "bag-shopping",
  "car-side",
  "gauge",
  "mountain-sun",
  "hand-holding-heart",
  "mobile",
  "database",
  "building",
  "store",
  "network-wired",
];

// Internal Picker Component
const Picker = ({ onClose, selectedValue, open }) => {
  const handleClose = () => onClose(selectedValue);
  const handleListItemClick = (value) => onClose(value);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select an Icon</DialogTitle>
      <List sx={{ pt: 0 }}>
        {pickerList.map((icon) => (
          <ListItem button onClick={() => handleListItemClick(icon)} key={icon}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <Icon icon={icon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={icon} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

Picker.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
