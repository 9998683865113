import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import ModelService from "services/ModelService";
import CategoryForm from "./Form";

function NewModel(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("icons");
  const resetFormFields = () => {
    setName("");
    setIcon("");
  };

  const publish = () => {
    const params = {
      name: name,
      icon: icon,
    };

    ModelService.createModel(params)
      .then(props.onCreate)
      .then(handleClose())
      .then(resetFormFields());
  };

  const handleChange = (props) => {
    if (props.name != null) setName(props.name);
    if (props.icon != null) setIcon(props.icon);
  };

  return (
    <>
      <Button className="m-2" variant="primary" onClick={handleShow}>
        New Category
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CategoryForm name={name} icon={icon} onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="ms-auto" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={publish}>
            Publish
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewModel;
