import React from "react";

import { NumericFormat } from "react-number-format";

export const NumericFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2} // Set the decimal scale to 2 for 2 decimal place
      thousandSeparator
      valueIsNumericString
    />
  );
});

export const PercentFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue, // Convert the value to a percentage
          },
        });
      }}
      decimalScale={2} // Set the decimal scale to 2 for 2 decimal places
      allowNegative={false} // Optionally, disallow negative values for percentages
      thousandSeparator
      valueIsNumericString
    />
  );
});
