import React, { Component } from "react";

import { Stack, Button, Grid } from "@mui/material";

import _ from "lodash";
import ESGChart from "components/ESGChart";
import ESGList from "components/ESGList";
import Confetti from "components/Confetti";
import { Icon } from "utils/fontawesome";
import { configureValues, sumValues, multipliedSum, arrangeModels } from "models/Game";

const round_template = {
  name: "",
  matchValue: 0,
  current_round: 0,
  total_rounds: 1,
  current_state: [],
  shuffle: false,
};

// returns a random number between low and high
const multiplierInRange = (multiplier) => {
  if (typeof multiplier !== "object") return multiplier ?? 1;

  const { low = 1, high = 1 } = multiplier;
  if (low === high || !low || !high) {
    return low || high || 1;
  } else {
    return parseFloat((Math.random() * (high - low) + low).toFixed(2));
  }
};

// coordinates loading the game and presenting it
class Play extends Component {
  constructor({ game, round, onSubmit, config }) {
    super();
    this.onSubmit = onSubmit;
    this.shuffle = config?.shuffle;
    this.state = {
      game: game, // wait for game to load
      round: round,
      round_config: { ...round_template },
    };
  }

  componentDidMount() {
    this.initializeGameRound();
  }

  initializeGameRound = () => {
    const { game, round } = this.state;
    if (!game) return console.warn("Failed to initialize game round. No game is mounted");

    let name = game.name || "unnamed game";
    let all_game_rounds = game.rounds;
    let current_game_round = all_game_rounds[round];
    if (!current_game_round) console.warn("Failed to initialize game round. No rounds to select");

    let data = configureValues(
      current_game_round.matchValue,
      arrangeModels({
        models: current_game_round.models,
        model_map: [],
      }),
      true // start at zero (i.e. don't randomize initial values)
    );

    data = this.shuffle ? _.shuffle(data) : data.sort((a, b) => a.name.localeCompare(b.name));

    // apply multipliers
    data = data.map((model) => {
      let res = {
        ...model,
        multiplier: multiplierInRange(model.multiplier),
      };
      return res;
    });

    let rc = {
      name: name,
      matchValue: current_game_round.matchValue,
      shuffle: current_game_round.shuffle,
      current_round: round,
      total_rounds: all_game_rounds.length,
      initial_state: [...data],
      current_state: structuredClone([...data]),
    };

    this.setState({ round_config: rc });
  };

  // receive changes from the list of sliders and apply them to state
  handleDataChange = (data) => {
    var rc = this.state.round_config;
    if (!rc) return;
    rc.current_state = [...data];
    this.setState({ round_config: rc });
  };

  set_submission = (newSubmission, nextRound) => {
    this.onSubmit(newSubmission);
  };

  // Render the game scene
  render() {
    const { round_config } = this.state;
    // const randomize = () => this.initializeGameRound();
    const setupGame = () => {
      let data = configureValues(round_config.matchValue, round_config.models, true);
      this.handleDataChange(data);
    };

    const handle_submit = () => {
      this.set_submission(
        {
          initial_state: round_config.initial_state,
          final_state: round_config.current_state,
        },
        setupGame
      );
    };

    // sum of the slider values
    const sum = (models) => sumValues(models);
    var totalSumNumber = sum(round_config.current_state ?? []);
    var matchValue = round_config.matchValue;
    var remainder = Math.floor(matchValue - totalSumNumber);
    const matchedSum = (models) => multipliedSum(models).toFixed(2);
    const isMatching = round_config.matchValue - sum(round_config.current_state) === 0;

    const matchingText = (models, match) =>
      `${match} + ${(matchedSum(models) - match).toFixed(2)} Matching!`;
    const colorForBool = (value) => (value ? "text-success" : "text-danger");

    // a color representing the direction in which
    // a person should allocate in order to reach
    // the allocation target (red, and green
    // representing minus. and add, respectively)
    const remaining_value_color = () => {
      const num = round_config.matchValue - sum(round_config.current_state);
      return colorForBool(num === 0);
    };

    // Prompt hinting to the player as to¡`
    // which direction they should be trending
    // towards while allocating / sliding
    const prompt_string = () => {
      const num = round_config.matchValue - sum(round_config.current_state);
      if (num === 0) {
        return "All set!";
      }
      return num >= 0 ? `Add ${num} more` : `Remove ${Math.abs(num)}!`;
    };

    const PromptText = () => <small className={remaining_value_color()}>{prompt_string()}</small>;

    const AllocationProgressView = () => {
      var segments = [];
      Object.values(round_config.current_state).forEach(({ name, color, value }) => {
        segments.push(
          <div
            key={name}
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${value}%`,
              background: color,
            }}
            aria-valuenow={value}
            aria-valuemin="0"
            aria-valuemax={matchValue}
          >
            <p>
              <b>{name}</b> ({((value / matchValue) * 100).toFixed()}%)
            </p>
          </div>
        );
      });

      return (
        <>
          <Confetti trigger={isMatching} />

          <Grid container spacing={2}>
            <Grid item xs={11}>
              <div
                className="progress"
                style={{
                  height: "2vh",
                  margin: 3,
                }}
              >
                {segments}

                <div
                  key="remainder"
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${Math.max(remainder, 0)}%`,
                    background: "gray",
                  }}
                  aria-valuenow={Math.max(remainder, 0)}
                  aria-valuemin="0"
                  aria-valuemax={matchValue}
                ></div>
              </div>
            </Grid>
            <Grid item xs={1}>
              {isMatching ? (
                <Icon icon="fa-circle-check" color="green" size="xl" />
              ) : remainder < 0 ? (
                <Icon icon="fa-circle-minus" color="red" size="xl" />
              ) : (
                <Icon icon="fa-circle-plus" color="red" size="xl" />
              )}
            </Grid>
          </Grid>
        </>
      );
    };

    // Actions to perform on the current game round
    const ActionsFooter = () => {
      return (
        <Stack gap={1}>
          {/* <Button variant="outlined" onClick={randomize}>
            Randomize
          </Button> */}
          <Button variant="contained" onClick={handle_submit} disabled={!isMatching}>
            Next
          </Button>
        </Stack>
      );
    };

    return (
      <>
        <div className="container col-12">
          <div className="row">
            <div className="col-5">
              <h2>
                <span className={colorForBool(isMatching)}>${sum(round_config.current_state)}</span>
                <sup>
                  <small className="text-muted">{` / ${round_config.matchValue}`}</small>
                </sup>
              </h2>
              <PromptText />
            </div>
            <div className="col-7 text-center">
              <h2 className={colorForBool(isMatching)}>
                ${matchedSum(round_config.current_state)}
              </h2>
              <small className={remaining_value_color()}>
                <b>{matchingText(round_config.current_state, round_config.matchValue)}</b>
              </small>
            </div>
            <div className="col-6"></div>
            <ESGChart
              data={round_config.current_state}
              weightedScale={
                matchedSum(round_config.current_state) / sum(round_config.current_state)
              }
            />
          </div>
        </div>
        <div>{AllocationProgressView()}</div>
        {/* Rows of models w/ sliders */}
        <div className="row p-4">
          <ESGList
            shuffle={round_config.shuffle}
            data={round_config.current_state}
            total={round_config.matchValue}
            onChange={this.handleDataChange}
          />
        </div>
        <ActionsFooter />
      </>
    );
  }
}

export default Play;
