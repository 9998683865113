import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CategoryForm from "./Form";
import ModelService from "services/ModelService";

const EditModelForm = ({ model, show, handleClose }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");

  React.useEffect(() => {
    setId(model.id);
    setName(model.name);
    setIcon(model.icon);
  }, [model]);

  const publish = () => {
    ModelService.updateModel({
      id: id,
      name: name,
      icon: icon,
    }).then(handleClose);
  };

  function deleteModel() {
    console.log("DELETE MODEL: ", name);
    ModelService.deleteModel(model.id).then(handleClose);
  }

  const handleChange = ({ name, icon }) => {
    if (name != null) setName(name);
    if (icon != null) setIcon(icon);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      id="editModelForm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CategoryForm name={name} icon={icon} onChange={handleChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="m-2" variant="danger" onClick={deleteModel}>
          Delete Category
        </Button>

        <Button className="ms-auto" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={publish}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModelForm;
