import React, { useState, useEffect } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import SubmissionService from "services/SubmissionService";
import moment from "moment";
import NukeSubmissions from "./NukeSubmissions";
import { exportCSV } from "services/Functions";
import { Button, Spinner } from "react-bootstrap";
// import { flatten } from "utils/flatten";

var FileSaver = require("file-saver");
const json2csv = require("json2csv").parse;

function SubmissionsTable(props) {
  const [isLoading, set_isLoading] = useState(false);
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    (async () => {
      let subs = await SubmissionService.getSubmissions();
      if (!subs) return;
      let map = subs.map((sub) => {
        // flatten the profile
        // var r = flatten(sub);
        // r.id = sub.id;
        // get each round, and relabel the keys
        Object.values(sub.rounds).forEach((value, index) => {
          // r = { ...r, [`round-${index}`]: flatten };
        });
        // format the date
        let timestamp = moment(sub.timestamp.toDate()).format(
          "MMMM Do YYYY, h:mm:ss a"
        );

        return { timestamp };
      });

      setSubmissions(map);
    })();
  }, []);

  const exportData = async () => {
    console.log("Downloading...");
    try {
      set_isLoading(true);
      const file = await exportCSV();
      const csv = json2csv(file.data);
      var blob = new Blob([csv], {
        type: "text/csv",
      });

      FileSaver.saveAs(blob, "esgmachine-submissions.csv");
    } catch (error) {
      console.log(error);
    } finally {
      set_isLoading(false);
    }
  };

  const SubmissionTools = (props) => {
    let btnContent = isLoading ? (
      <span>
        Exporting...
        <Spinner
          as="span"
          animation="border"
          className="mx-2"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </span>
    ) : (
      "Export Data"
    );

    return (
      <>
        <Button
          variant="outline-primary"
          onClick={exportData}
          disabled={isLoading}
        >
          {btnContent}
        </Button>
        <NukeSubmissions />
      </>
    );
  };

  const columns_for = (data) => {
    if (!data[0]) return [];
    let r = Object.keys(data[0]);
    return r;
  };

  return (
    <>
      <span>
        <h1 className="h3">Submissions</h1>
        <SubmissionTools />
      </span>

      <Table height={800} data={submissions}>
        {submissions &&
          columns_for(submissions).map((data_key, index) => (
            <Column key={index}>
              <HeaderCell>{data_key}</HeaderCell>
              <Cell dataKey={data_key} />
            </Column>
          ))}
      </Table>
    </>
  );
}
export default SubmissionsTable;
