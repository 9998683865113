import React from "react";
import ESGRow from "./ESGRow";
import { useMotionValue, Reorder } from "framer-motion";
import { useRaisedShadow } from "utils/use-raised-shadow";

const style = {
  listStyle: "none",
  padding: 0,
  margin: 0,
  fontWeight: "500",
  fontSize: "24px",
};

const ulStyle = {
  ...style,
  position: "relative",
};

const liStyle = {
  ...style,
  marginBottom: "10px",
  width: "100%",
  padding: "15px 18px",
  position: "relative",
  background: "white",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexShrink: 0,
  cursor: "grab",
  borderRadius: "5px",
  border: "1px solid #DDDDDD",
  zIndex: "auto",
};

export default function ESGSort({ data, onReorder }) {
  return (
    <Reorder.Group axis="y" values={data} onReorder={onReorder} style={ulStyle}>
      {data.map((item) => (
        <Item key={item.name} item={item} />
      ))}
    </Reorder.Group>
  );
}

const Item = ({ item }) => {
  // const [isDragging, setIsDragging] = useState(false);
  const handleEvent = (event) => {
    // setIsDragging(event.type === "mousedown");
  };

  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  return (
    <Reorder.Item
      value={item}
      style={{ boxShadow, y, ...liStyle }}
      onMouseDown={handleEvent}
      onMouseUp={handleEvent}
      whileTap={{ scale: 1.02, zIndex: 300 }}
      whileHover={{
        scale: 1.01,
      }}
    >
      <ESGRow item={item} />
    </Reorder.Item>
  );
};
