import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SubmissionService from "services/SubmissionService";

export default function DeleteGameSubmissions({ gameId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const confirm = async () => {
    const confirmed = window.confirm(
      "You are about to delete all submissions for this game. Are you sure?"
    );
    if (confirmed) {
      handleClose();
    }
  };

  return (
    <>
      <Button className="m-2" variant="danger" onClick={handleShow}>
        Reset Submissions
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete all submissions for this game?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you would like to delete all submissions for this game?{" "}
          <br />
          Submissions will be erased and unrecoverable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () =>
              await SubmissionService.clearSubmissionsForGame(gameId, confirm)
            }
          >
            Delete All Submissions
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
