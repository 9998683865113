import react, { useState } from "react";
import Dashboard from "./Dashboard";
import Unauthorized from "pages/auth/unauthorized";
import { auth } from "../../services/firebase";
import { Spinner } from "react-bootstrap";

function Admin() {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  react.useEffect(() => {
    setIsLoading(true);
    const unlisten = auth.onAuthStateChanged((authUser) => {
      authUser
        ?.getIdTokenResult()
        .then((idTokenResult) => {
          setIsAdmin(!!idTokenResult.claims.admin);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
    return () => {
      unlisten();
    };
  }, []);

  return isLoading ? (
    <Spinner
      as="span"
      animation="border"
      className="mx-2"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  ) : isAdmin ? (
    <Dashboard />
  ) : (
    <Unauthorized />
  );
}

export default Admin;
