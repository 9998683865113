import { TextField, Box } from "@mui/material";

import { Icon } from "utils/fontawesome";
import { NumericFormatCustom } from "../../../utils/NumericFormatCustom";

const EditCategoryRow = ({
  index,
  item: { name, icon, color, multiplier },
  readOnly,
  onChange,
  mode,
}) => {
  function handleChange(key, value) {
    onChange({
      index: index,
      multiplier:
        mode === "donut"
          ? {
              ...multiplier,
              [key]: !!value ? Number(value) : undefined,
            }
          : Number(value),
    });
  }

  return (
    <li key={`${index}-${name}`} className="list-group-item">
      <div className="d-flex justify-content-between">
        <span>
          <Icon icon={icon} color={color} />
          <strong className="px-2">{name}</strong>
        </span>

        <Box display="flex" gap={2} p={1}>
          {mode === "donut" ? (
            <>
              <TextField
                size="small"
                label="Multiplier: Low"
                value={multiplier?.low ?? "1"}
                onChange={(e) => handleChange("low", parseFloat(e.target.value) || 0)}
                name="multiplierLow"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                variant="outlined"
                placeholder="1"
                disabled={readOnly}
                style={{ width: "120px" }}
              />
              <TextField
                size="small"
                label="High"
                value={multiplier?.high ?? "1"}
                onChange={(e) => handleChange("high", parseFloat(e.target.value) || 0)}
                name="multiplierHigh"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                variant="outlined"
                placeholder="1"
                disabled={readOnly}
                style={{ width: "120px" }}
              />
            </>
          ) : (
            <TextField
              size="small"
              label="Multiplier"
              value={multiplier?.low ?? multiplier?.hight ?? multiplier ?? "1"}
              onChange={(e) => handleChange("value", parseFloat(e.target.value) || 0)}
              name="multiplier"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              placeholder="1"
              disabled={readOnly}
              style={{ width: "120px" }}
            />
          )}
        </Box>
      </div>
    </li>
  );
};

export default EditCategoryRow;
