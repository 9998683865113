import React, { Component } from "react";
import { ProgressBar, Button, Container } from "react-bootstrap";
import Select from "react-select";
import { Stack, Typography, TextField } from "@mui/material";

class Register extends Component {
  constructor(props) {
    super(props);
    const { questions } = props;
    this.state = {
      questions: questions,
      step: 0,
      answers: {},
    };
  }

  submit() {
    this.setState({ show: false });
  }

  get steps() {
    const { questions } = this.state;
    return Object.keys(questions).length;
  }

  get question() {
    const { questions } = this.state;
    return questions[this.state.step];
  }

  setAnswer = (key, value) => {
    var answers = this.state.answers;
    answers[key] = value;
    this.setState({ answers });
  };

  render() {
    const { answers } = this.state;
    return (
      <Container>
        <h1 className="flex-d justify-content-center ">{this.props.title}</h1>

        <div className="progressBar">
          <ProgressBar className="orange-progress-bar">
            <ProgressBar
              now={this.state.step + 1}
              max={this.steps + 1}
              min={1}
            />
          </ProgressBar>
        </div>
        {this.question && (
          <QuestionRow
            question={this.question}
            value={answers[this.question]}
            onChange={this.setAnswer}
          />
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button
            sx={{ width: "50%" }}
            variant="secondary"
            onClick={this.prevStep}
            disabled={!this.canStepBack()}
          >
            Back
          </Button>
          <Button
            sx={{ width: "50%" }}
            disabled={!this.canStepNext()}
            variant="primary"
            onClick={this.nextStep}
          >
            Next
          </Button>
        </Stack>
      </Container>
    );
  }

  canStepBack = () => {
    const { step } = this.state;
    return step > 0;
  };

  canStepNext = () => {
    // const { questions, steps, step } = this.state;
    if (this.state.step < this.steps) {
      return this.state.answers[this.question?.question] != null;
    } else {
      return true;
    }
  };

  // go back to previous step
  prevStep = (e) => {
    e.preventDefault();
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = (e) => {
    const { step } = this.state;
    if (step < this.steps - 1) {
      this.setState({ step: step + 1 });
    } else {
      this.props.onSubmit(this.state.answers);
    }
  };
}

const QuestionRow = ({ question, value, onChange }) => {
  return (
    <Stack paddingTop={1} paddingBottom={1} spacing={2}>
      <Typography variant="headline">{question.description ?? ""}</Typography>
      {question.inputType === "select" ? (
        <Select
          key={question.question}
          size="small"
          placeholder={`- Pick ${question.question}`}
          value={value}
          label={question.question}
          onChange={(e) => onChange(question.question, e)}
          options={question.options.map((i) => {
            return { label: i, value: i };
          })}
        />
      ) : (
        <TextField
          size="small"
          variant="outlined"
          label={question.question}
          value={value}
          onChange={(e) => onChange(question.question, e.target.value)}
        />
      )}
    </Stack>
  );
};

export default Register;
