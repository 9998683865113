import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PublishButton from "./PublishButton";
import GameService from "services/GameService";
import GameForm from "./Form";

function NewGame() {
  const [show, setShow] = useState(false);
  const [gameConfig, setGameConfig] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const publishGame = (status) => {
    console.log("PUBLISH GAME: ", gameConfig);
    GameService.createGame({
      ...gameConfig,
      status,
      route: `https://esgmachine.com/${gameConfig.name
        .toLowerCase()
        .replace(" ", "")}`,
    }).then(() => {
      handleClose();
    });
  };

  // Final render of the Form Component
  return (
    <>
      <Button className="m-2" variant="primary" onClick={handleShow}>
        New Game
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>New Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GameForm value={gameConfig} onChange={setGameConfig} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="ms-auto" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <PublishButton status={gameConfig.status} onClick={publishGame} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewGame;
