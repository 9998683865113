const reMatrix = {
  baseline: {
    0: {
      key: "netOperatingIncome",
      value: 1849000,
      title: "Net Operating Income",
      prefix: "$",
    },
    1: {
      key: "onSiteCO2",
      value: 1300,
      title: "On-site CO2 Emissions",
      suffix: " Tonnes",
    },
    2: {
      key: "Tenant & Community Satisfaction",
      value: 75,
      title: "Tenant & Community Satisfaction",
      suffix: "%",
    },
    3: {
      key: "offSiteCO2",
      value: 1300,
      title: "Off-site CO2 Emissions",
      suffix: " Tonnes",
    },
  },
  inputControls: {
    0: {
      title: "Heating & Cooling",
      color: "#E9E99D",
      icon: "fan",
      options: {
        0: {
          title: "Air Side Economizers",
          description:
            "Air side economizers convert air from the outdoors into usable air indoors, and these systems are most functional in hot climates. The purpose of this retrofit is to reduce cooling costs and electricity usage. If used and maintained properly, and economizer can significantly reduce cooling bills. We expect a positive effect on financials after the economizer pays off long-term.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 11429.3124558117 },
            1: { title: "On-site CO2 Emissions", value: -105.232483832335 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        1: {
          title: "Smart Thermostats ",
          description:
            "Smart thermostats can monitor air temperatures and adjust based on human usage of space and over heating and cooling. The purpose of this retrofit is to reduce unnecessary heating and cooling of spaces. This effects how much the HVAC systems run. We expect a positive effect on financials due to this minimal investment.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 12319.0573165658 },
            1: { title: "On-site CO2 Emissions", value: -161.356475209581 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        2: {
          title: "Chiller Plant Retrofits",
          description:
            "Chiller plants deliver cold water and refrigeration inside buildings, and updating the existing infrastructure can save energy and be more efficient. The purpose of this retrofit is to increase the efficiency of the existing chiller system. The retrofit can reduce cooling bills. We expect a positive effect on financials after the chiller plant pays off long-term.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -24920.1896689101 },
            1: { title: "On-site CO2 Emissions", value: -301.666453652695 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        3: {
          title: "Air Source Heat Pumps",
          description:
            "Air source heat pumps transfer heat from the outside into hot water, which can be used to heat buildings. The purpose of this retrofit is to cut electricity and heating use. The retrofit can significantly reduce heating bills. We can expect a positive financial impact once the total building system is replaced.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -81615.0086843391 },
            1: { title: "On-site CO2 Emissions", value: -547.208915928144 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
      },
    },
    1: {
      title: "Building Envelope",
      color: "#9E9EE0",
      icon: "building",
      options: {
        0: {
          title: "Concealing Air Leakage to Reduce Air Infiltration",
          description:
            "Concealing air leakage in commercial buildings will reduce air infiltration from the outdoors. The purpose of the retrofit is to cut energy costs and heating and cooling expenses. This affects the run time and intensity of HVAC units. We expect a positive impact on financials due to lower energy bills.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 11430.1484599477 },
            1: { title: "On-site CO2 Emissions", value: -105.232483832335 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        1: {
          title: "Apply Roof Insulation ",
          description:
            "Roof insulation helps to keep the building at its desired temperature. The purpose of this retrofit is to save energy costs and reduce heating and cooling expenses. This affects the run time and intensity of HVAC units. We expect a positive impact on financials due to lower energy bills.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 1430.1484599477 },
            1: { title: "On-site CO2 Emissions", value: -14.03 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        2: {
          title: "Replace Windows & Doors with Low Emissivity Units",
          description:
            "Low emissivity windows and doors provide an extra layer of insulation to the building facade. The purpose of the retrofit is to keep energy costs down and reduce heating and cooling expenses. This affects the run time and intensity of HVAC units. We expect a positive impact on financials due to lower energy bills.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -17069.8515400523 },
            1: { title: "On-site CO2 Emissions", value: -322.712950419162 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        3: {
          title: "Adding Partition Wall to Existing Interior Wall",
          description:
            "The partition wall is an extra wall layer that is added on top of the existing interior wall. The purpose of this retrofit is to provide an extra layer of insulation and to keep specific rooms at their desired temperature. This affects the run time and intensity of HVAC units. We expect a positive impact on financials due to lower energy bills.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -123448.428252584 },
            1: { title: "On-site CO2 Emissions", value: -399.883438562874 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
      },
    },
    2: {
      title: "Ventilation",
      color: "#E680A1",
      icon: "wind",
      options: {
        0: {
          title: "Demand Control Ventilation Systems",
          description:
            "Demand control ventilation systems monitor the amount of CO2 in the indoor air and only run ventilation systems on demand. The purpose of the retrofit is to reduce the electricity required by not constantly running the ventilation fans. This will reduce electricity bills. We expect a positive effect on financials.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 7500 },
            1: { title: "On-site CO2 Emissions", value: -105.232483832335 },
            2: { title: "Tenant & Community Satisfaction", value: 2 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        1: {
          title: "Convert CV Systems to VAV ",
          description:
            "The purpose the VAV system upgrade is to make ventilation for efficient and less costly through more precise temperature control. The purpose of this retrofit is to reduce energy consumption. The retrofit will reduce electricity bills for ventilation. We expect to see a positive effect on financials long-term.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 100 },
            1: { title: "On-site CO2 Emissions", value: -189.418470898204 },
            2: { title: "Tenant & Community Satisfaction", value: 2 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        2: {
          title: "Ventilation Fan Upgrades",
          description:
            "Upgrading existing ventilation fans can reduce their noise, improve their efficiency, and increase their lifespan. The purpose of the retrofit will reduce the electricity needed to run the fans. This will reduce the electricity bills overall. We expect to see a positive effect on financials.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -3438.22160263281 },
            1: { title: "On-site CO2 Emissions", value: -224.495965508982 },
            2: { title: "Tenant & Community Satisfaction", value: 2 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        3: {
          title: "Improve Air Filtration",
          description:
            "Improving air filtration will put less strain on ventilation systems and increase employee health and well-being in the spaces. The purpose of the retrofit is to improve the air quality. This will result in greater employee productivity. We expect a positive effect on financials due to employee productivity equating to more progress for the company.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -15888.2216026328 },
            1: { title: "On-site CO2 Emissions", value: -224.495965508982 },
            2: { title: "Tenant & Community Satisfaction", value: 5 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
      },
    },
    3: {
      title: "Green Spacing",
      color: "#B1ECD4",
      icon: "leaf",
      options: {
        0: {
          title: "Public Patio and Paving",
          description:
            "Adding a paved area where people can work, socialize, and eat will enhance the building's exterior and attractiveness. There are no direct savings in terms of energy and financials. We expect a positive return on investment due to more people being drawn to an attractive space.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -6500 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 5 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        1: {
          title: "Improve Walkways & Sidewalks",
          description:
            "Improving existing sidewalks and adding new ones can create an attractive exterior for commercial buildings. While these enhancements will not save costs, they will attract more people into the building. We expect to see a positive return on financials due to more attractive spaces.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -14500.732565811 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 7 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        2: {
          title: "Add Professional Landscaping & Gardening",
          description:
            "Adding professional landscaping and plants can add to building attractiveness. This will not directly save costs or energy but will promote use of the building. We expect a positive return on financials due to a more attractive exterior.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -25606.131528455 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 9 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
        3: {
          title: "Update Outdoor Signage and Lighting",
          description:
            "Updating the existing signage and lighting are perhaps the most pertinent ways to attract people into the space since lighting and signage are defining of the building's use and character. There are no direct savings, but the use of energy efficient lighting can result in savings. We expect a positive return on investment due to more people being drawn to the space.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -43609.7943575098 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 12 },
            3: { title: "Off-site CO2 Emissions", value: 0 },
          },
        },
      },
    },
    4: {
      title: "Off-site Investments",
      color: "#D19974",
      icon: "warehouse",
      options: {
        0: {
          title: "Solar Panel Racks On/Near the Building",
          description:
            "Installing solar panels on rooftops of buildings will generate a marginal amount of energy. However, the impact is minimal as roof to property size is small.",
          deltaValues: {
            0: { title: "Net Operating Income", value: 6645.19131960687 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: -39.052944 },
          },
        },
        1: {
          title: "Buy Green Energy Instead of Brown Energy",
          description:
            "Buying green energy reduces the share of emissions of operating the property. Not all emissions will be cut as a continious green energy supply is not yet feasible and the buildings might use other sources of energy than electricity.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -8784.41107690723 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: -364.494144 },
          },
        },
        2: {
          title: "Build Solar Park Offsite",
          description:
            "Off-site solar parks directly enables property owners to reduce off-site emissions as the share of green energy largely increases and thus emissions reduce. In addition, the plant will be additional, resulting in more emission reductions compared to buying green energy from the grid.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -33248.37133 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: -820.111824 },
          },
        },
        3: {
          title: "Carbon Offsets",
          description:
            "Carbon off-sets can be used to reduce the off-site emissions of the property to zero. These off-sets are priced at 90 USD per Tonne of CO2 reflecting current market prices.",
          deltaValues: {
            0: { title: "Net Operating Income", value: -76597.1391732846 },
            1: { title: "On-site CO2 Emissions", value: 0 },
            2: { title: "Tenant & Community Satisfaction", value: 0 },
            3: { title: "Off-site CO2 Emissions", value: -1300 },
          },
        },
      },
    },
  },
};

export default reMatrix;
