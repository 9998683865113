import React from "react";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Stack, Typography } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const MatrixChart = ({ data }) => {
  const { baseline, suggestedRange, chartDataForBaseline } = data;

  const options = (value, index) => ({
    indexAxis: "y",
    scaleBeginAtZero: false,
    spanGaps: true,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    aspectRatio: 40 / 9,
    scales: {
      y: {
        afterFit: function (scaleInstance) {
          scaleInstance.width = 110; // sets the width to 100px
        },
      },
      x: {
        min: value.chartMinX || suggestedRange(index)?.min,
        max: value.chartMaxX || suggestedRange(index)?.max,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value;
          },
        },
      },
    },
    plugins: {
      title: {
        align: "center",
        display: false,
        text: "Relative changes compared to a no-retrofitting-scenario",
      },
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const r = formatLabel(ctx.raw, ctx.dataIndex);

            return r;
          },
        },
      },
      subtitle: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        labels: {
          value: {
            backgroundColor: function (ctx) {
              return "#ffffff";
            },
            borderColor: "white",
            borderWidth: 2,
            borderRadius: 4,
            color: function (ctx) {
              return ctx.dataset.backgroundColor;
            },
            formatter: function (value, context) {
              return formatLabel(value, context.dataIndex);
            },
            padding: 1,
          },
        },
      },
    },
  });

  function formatLabel(val, index) {
    const min = baseline[index]?.chartMinX || suggestedRange(index)?.min;
    if (val <= min) return Number(val[0]).toFixed(2);
    return Number(val[1]).toFixed(2);
  }

  return (
    <Stack alignItems="center">
      <Typography variant="caption" fontWeight="bold">
        Relative changes compared to a no-retrofitting-scenario
      </Typography>
      {Object.values(baseline).map((e, i) => (
        <Bar
          key={i}
          options={options(e, i)}
          data={chartDataForBaseline(e, i)}
          style={{
            // position: "sticky",
            top: "1rem",
            minWidth: "275",
            maxWidth: "500",
          }}
        />
      ))}
    </Stack>
  );
};

export default MatrixChart;
