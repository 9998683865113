import * as Sentry from "@sentry/react";
import { Stack, Box, Typography, Button } from "@mui/material";

Sentry.init({
  dsn: "https://5c4acad4b29d5298688438237a611b3d@o4507963061698560.ingest.us.sentry.io/4508132839915520",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/us-central1-esgmachine-36d4e.cloudfunctions.net/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV || "development",
  enabled: process.env.REACT_APP_ENV !== "development",
});

export default function BugCatcher({ children }) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <Box
          sx={{
            p: 0,
            m: 0,
            width: "100vw",
            height: "100vh",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <Stack
            sx={{
              bgcolor: "#0078d7",
              color: "white",
              height: "100vh",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              fontFamily: "Consolas, monospace",
              textAlign: "center",
              padding: 4,
            }}
          >
            <Typography variant="h2" sx={{ mb: 2, fontWeight: "bold" }}>
              :(
            </Typography>
            <Typography variant="h4" sx={{ mb: 4 }}>
              Well that&apos;s not supposed to happen.
            </Typography>
            <Typography variant="h5" sx={{ mb: 4 }}>
              We&apos;re just collecting some error info, and then we&apos;ll restart for you.
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              If you&apos;d like to know more, here is the error:
            </Typography>
            <Box component="span" variant="body2" sx={{ mb: 2, display: "block" }}>
              {error.toString()}
            </Box>
            <Button
              variant="contained"
              color="inherit"
              sx={{ color: "#0078d7", bgcolor: "white" }}
              onClick={() => window.location.reload()}
            >
              Restart
            </Button>
          </Stack>
        </Box>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
