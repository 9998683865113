import _ from "lodash";

export function suggestedRangeForBaseline(
  baselineKey,
  baseline,
  inputControls
) {
  const { title, value = 0 } = baseline[baselineKey];

  const options = Object.values(inputControls ?? {})
    .flatMap(({ options }) => Object.values(options ?? {}))
    .flatMap(({ deltaValues }) => Object.values(deltaValues ?? {}))
    .filter((e) => e.title === title)
    .map((e) => parseFloat(e.value) || 0);

  if (_.isEmpty(options)) return { min: 0, max: 0 }; // No options found

  const minValue = Math.min(...options, value).toFixed(2);
  const maxValue = Math.max(...options, value).toFixed(2);

  return { min: minValue, max: maxValue };
}
