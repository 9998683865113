import React from "react";
import { Container, Alert } from "react-bootstrap";
import Auth from "services/auth";

const SignOut = () => {
  Auth.signout();
  return (
    <Container className="py-4">
      <Alert variant="primary">
        <span className="alert-heading h4">{"Bye bye"}</span>
        <p>You have been signed out! We hope to see you again soon!</p>
      </Alert>
    </Container>
  );
};

export default SignOut;
