import {
  listUsers,
  grantAdmin,
  revokeAdmin,
  resetPassword,
} from "../services/Functions";

const listAllUsers = async () => {
  var results = [];
  await listUsers()
    .then((results) => results.data)
    .then((listUsersResult) => {
      listUsersResult.forEach((doc) => {
        doc.customClaims = Object.keys(doc.customClaims || {});
        results.push(doc);
      });
    })
    .catch((error) => {
      console.log("Error getting users: ", error);
    });

  return results;
};

const setUserIsAdmin = async (uid, isAdmin) => {
  let req = isAdmin ? grantAdmin : revokeAdmin;
  return req({ uid: uid })
    .then((result) => console.log)
    .catch((error) => console.error(error.message));
};

const resetPasswordForUser = async (email) => {
  return resetPassword({ email: email });
};

const UserService = {
  listAllUsers,
  setUserIsAdmin,
  resetPasswordForUser,
};

export default UserService;
