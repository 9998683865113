import { models, db } from "./firebase";
import Timestamp from "../utils/Timestamp";

export const getModels = async () => {
  return new Promise((resolve, reject) => {
    models.onSnapshot((snapshot) => {
      let updatedData = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      resolve(updatedData);
    }, reject);
  });
};

// initialize a new model based on the most recent
// published model on the networks's database
const getModel = async () => {
  var config;
  let models = await getModels();

  await models
    .orderBy("timestamp", "desc")
    .limit(1)
    .get()
    .then((querySnapshot) => {
      let doc = querySnapshot.docs[0];
      config = doc.data();
      config.id = doc.id;
      config.model_map = models;
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  return config;
};

// initialize a new model based on the most recent
// published model on the networks's database
const createModel = async ({ name, icon }) => {
  let result = await models.add({
    name: name,
    icon: icon,
    timestamp: Timestamp(),
  });
  let data = await result.get().catch((error) => {
    console.error("Error writing document: ", error);
  });

  return data;
};

// updates a model with new parameters
const updateModel = async ({ id, name, icon }) => {
  return await models.doc(id).update({
    name: name,
    icon: icon,
    timestamp: Timestamp(),
  });
};

// Deletes a model by id
const deleteModel = (id) => {
  let result = models
    .doc(id)
    .delete()
    .then(console.log("Document successfully deleted model!"))
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
  return result;
};

// destroy all existing documents within the model collection
const nukeModels = (confirm) => {
  if (confirm()) {
    let batch = db.batch();
    models
      .get()
      .then(function (snapshot) {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .catch((e) => alert(e));
  } else {
  }
};

const ModelService = {
  getModels,
  getModel,
  createModel,
  updateModel,
  deleteModel,
  nukeModels,
  Timestamp,
};

export default ModelService;
