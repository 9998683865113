import * as d3 from "d3";

export const color_scheme = [
  {
    name: "Turbo",
    value: "turbo",
    scale: d3.interpolateTurbo,
  },
  {
    name: "Viridis",
    value: "viridis",
    scale: d3.interpolateViridis,
  },
  {
    name: "Inferno",
    value: "inferno",
    scale: d3.interpolateInferno,
  },
  {
    name: "Magma",
    value: "magma",
    scale: d3.interpolateMagma,
  },
  {
    name: "Plasma",
    value: "plasma",
    scale: d3.interpolatePlasma,
  },
  {
    name: "Cividis",
    value: "cividis",
    scale: d3.interpolateCividis,
  },
  {
    name: "Warm",
    value: "warm",
    scale: d3.interpolateWarm,
  },
  {
    name: "Cool",
    value: "cool",
    scale: d3.interpolateCool,
  },
  {
    name: "Rainbow",
    value: "rainbow",
    scale: d3.interpolateRainbow,
  },
  {
    name: "Sinebow",
    value: "sinebow",
    scale: d3.interpolateSinebow,
  },
];

const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: false,
};

export function calculatePoint(i, intervalSize) {
  const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize;
}

/* Must use an interpolated color scale, which has a range of [0, 1] */
export function interpolateColors(dataLength, scheme_id) {
  const { colorStart, colorEnd } = colorRangeInfo;
  const colorRange = colorEnd - colorStart;
  const intervalSize = colorRange / dataLength;

  let i;
  let colorPoint;
  const colorArray = [];

  let scheme = color_scheme.filter((i) => {
    return i.value === scheme_id;
  })[0];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize);
    colorArray.push(
      scheme?.scale(colorPoint) ?? d3.interpolateSinebow(colorPoint)
    );
  }

  return colorArray;
}
