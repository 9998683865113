import React, { useState } from "react";
import {
  Container,
  ToggleButton,
  ToggleButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Read as GamesTable } from "sections/admin/game";
import { Read as SubmissionsTable } from "sections/admin/analytics";
import { Read as ModelsTable } from "sections/admin/models";
import { Read as UsersTable } from "sections/admin/user";

const sections = [
  {
    value: "games",
    label: "Games",
    details: "Create and manage games.",
    element: <GamesTable />,
  },
  {
    value: "submissions",
    label: "Submissions",
    details: "Analyize submitted game data",
    element: <SubmissionsTable />,
  },
  {
    value: "categories",
    label: "Categories",
    details: "Manage social issue templates.",
    element: <ModelsTable />,
  },
  {
    value: "users",
    label: "Users",
    details: "Manage user accounts and their access.",
    element: <UsersTable />,
  },
];

const Dashboard = (props) => {
  const [activeSection, setActiveSection] = useState("games");
  const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

  function Content({ name }) {
    return sections.find((section) => section.value === activeSection).element;
  }
  return (
    <Container fluid className="d-grid gap-2">
      <ToggleButtonGroup
        className="p-2"
        type="radio"
        name="gameMode"
        defaultValue={"games"}
      >
        {sections.map(({ value, label, details }) => (
          <OverlayTrigger
            key={value}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(details)}
          >
            <ToggleButton
              id={value}
              key={value}
              value={value}
              active={value === activeSection}
              variant={
                value === activeSection
                  ? "outline-primary"
                  : "outline-secondary"
              }
              checked={value === activeSection}
              onClick={(e) => {
                setActiveSection(value);
              }}
            >
              {label}
            </ToggleButton>
          </OverlayTrigger>
        ))}
      </ToggleButtonGroup>
      <Content name={activeSection} />
    </Container>
  );
};
export default Dashboard;
