import { db, games, submissions } from "./firebase";
import Timestamp from "../utils/Timestamp";

const serializeTimestamp = (ts) => {
  if (!ts) return;
  const { seconds, nanoseconds } = ts;
  const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  const date = new Date(milliseconds);
  return date;
};

const getSubmissions = async (gameId) => {
  var results = [];

  if (!gameId) {
    await submissions
      .orderBy("timestamp", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          var sub = {
            id: doc.id,
            ...data,
            date: serializeTimestamp(data.timestamp),
          };
          results.push(sub);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  } else {
    await submissions
      .orderBy("timestamp", "desc")
      .where("game", "==", db.doc(`/games/${gameId}`))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          var sub = {
            id: doc.id,
            ...data,
            date: serializeTimestamp(data.timestamp),
          };
          results.push(sub);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  return results;
};

const addSubmission = (gameId, results) => {
  const submission_data = {
    ...(results.rounds && { rounds: results.rounds }),
    ...(results.ipAddress && { ipAddress: results.ipAddress }),
    ...(results.profile && { profile: results.profile }),
    ...(results.sessionId && { sessionId: results.sessionId }),
    game: games.doc(`${gameId}`),
    timestamp: Timestamp(),
  };

  console.log("Adding submission", submission_data);

  return submissions.add(submission_data).catch(console.error);
};

const nukeSubmissions = (confirm) => {
  if (confirm()) {
    let batch = db.batch();
    submissions
      .get()
      .then(function (snapshot) {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .catch((e) => alert(e));
  } else {
  }
};

const clearSubmissionsForGame = async (gameId, confirm) => {
  if (confirm()) {
    const batch = db.batch();
    const querySnapshot = await submissions.where("game", "==", games.doc(gameId)).get();
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    try {
      await batch.commit();
      console.log("Submissions for game with ID", gameId, "cleared successfully.");
    } catch (error) {
      console.error("Error clearing submissions:", error);
    }
  } else {
    console.log("Clearing submissions canceled.");
  }
};

const SubmissionService = {
  getSubmissions,
  addSubmission,
  nukeSubmissions,
  clearSubmissionsForGame,
};

export default SubmissionService;
